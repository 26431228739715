<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="
                page-title-box
                d-sm-flex
                align-items-center
                justify-content-between
              ">
            <h4 class="mb-sm-0">Saham</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item active">Daftar Saham</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Daftar Saham</h4>
            </div>

            <div class="card-body">
              <DataTable :url="dataTableUrl" :columns="columnHeaders" :actions="actions" :reload="reloadDataTable" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import DataTable from "../../components/datatable/DataTable.vue";
import { endpoint } from "../../host";
import globalService from "../../services/global-service";
import permissonService from "../../services/permisson-service";

export default {
  components: {
    DataTable,
  },

  created() { },

  data() {
    return {
      permission: permissonService,
      list: {},
      filter: {
        is_approved_hrd: "",
      },
      reloadDataTable: false,
      dataTableUrl: endpoint.share,
      actions: [
        {
          icon: "bx bx-transfer-alt",
          color: "btn-warning",
          tooltip: "transfer",
          onclick: (data) => {
            this.$router.push({
              name: "share-transfer",
              params: { id: data.id },
            });
          },
        },
        {
          icon: "bx bx-download",
          color: "btn-info",
          tooltip: "download",
          onclick: (data) => {
            let url = endpoint["certificate.download"] + `/${data.share_number}`;
            window.open(url, "_blank");
          },
        },
      ],
      columnHeaders: [
        { name: "share_number", text: "Nomor Sertifikat" },
        { name: "share_period.name", text: "Periode" },
        { name: "slot", text: "Slot" },
        { name: "owner.name", text: "Pemilik" },
        //   {
        //     text: "Saldo",
        //     render: (data) => {
        //       return globalService.toIdr(data.user.wallet.saldo);
        //     },
        //   },
      ],
    };
  },
};
</script>
  
  <style>
  </style>